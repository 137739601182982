@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* FORMS ****************************************************/
/* #content block */
form {
  .form-group {
    padding: 0;

    .form-control {
      background-color: #f8f9fa;
      border: 1px solid $blue-light;

      &:hover,
      &:-webkit-autofill:hover {
        background-color: #dae0e5;

        //box-shadow: $blue-light 3px 3px 3px 0.403944px;
      }

      &:-webkit-autofill {
        background-color: rgb(248, 249, 250) !important;
        color: $font-color-base !important;
        -webkit-text-fill-color: $font-color-base;
        transition: background-color 5000s ease-in-out 0s;
      }

      &:focus,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 0.2rem $orange;
        box-shadow: 0 0 0 0.2rem $orange;
        -webkit-text-fill-color: $font-color-base;

        //box-shadow: $blue-light 3px 3px 3px 0.403944px;
      }

      &.is-invalid {
        border-color: $font-color-error;
        background-image: none;
        background-color: rgb(248, 249, 250);

        &:focus,
        &:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 5px 0.2rem $font-color-error;
          box-shadow: 0 0 5px 0.2rem $font-color-error;
        }
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $font-color-error;
        opacity: 1; /* Firefox */
        font-style: italic;
      }

      option {
        box-sizing: content-box;
        line-height: 30px;
      }
    }

    select.is-invalid {
      color: $font-color-error;
      opacity: 1; /* Firefox */
      font-style: italic;

      option {
        font-style: normal;
        color: $font-color-base;
      }
    }

    label {
      width: 100%;

      .error {
        display: block;
        padding: 10px 0;
        font-style: italic;
        color: $font-color-error;
      }
    }
  }

  .block-switch {
    height: 0;
    opacity: 0;
    //transition: opacity 0.5s linear;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s ease;

    &.is-active {
      height: auto;
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .confirmemail {
    display: none;
  }
}
.input-group {

  &.date {

    .input-group-addon {
      padding: 11px 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      color: #555;
      text-align: center;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:first-child {
        border-right: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-left: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &:hover {
        background-color: $orange;
        color: #FFF;
        cursor: pointer;
      }
    }

    &:hover {

      .input-group-addon {
        background-color: $orange;
        color: #FFF;
      }
    }
  }
}