@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* googlemap **********************************/
.googlemap {
  float: none;
  width: 100%;
  height: 430px;
  margin: 70px 0 40px 0;
  padding: 0;
  border-bottom: 10px solid #221E1F;
}
.googlemap_inner {
}
.googlemap_inner iframe {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*width: 100% !important;*/
  /*height: 100% !important;*/
}
.googlemap .txt_label {
  display: block;
  font-size: 20px;
  padding: 0 0 10px 0;
}
#map_canvas {
  height: 430px;
}