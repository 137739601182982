@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* file downloads ******************************/
.files {
  padding: 0 0 0 0;
}
.downloads_list {
  display: block;
  width: 100%;
  margin: 0 0 50px 0 !important;
}
.fileblock {
  display: block;
  clear: both;
  width: 100%;
  height: auto;
  min-height: 80px;
  margin: 0 0 0px 0 !important;
  padding: 0 !important;
  border-bottom: 1px solid $blue-light;
  background: none !important;

  a {
    display: table;
    float: left;
    width: calc(100% - 170px);
    height: auto;
    min-height: 80px;
    line-height: 22px;
    padding: 10px;
    text-decoration: none;

    span {
      display: table-cell;
      vertical-align: middle;
    }

    &:hover {
      color: $orange;
      text-decoration: underline;
    }
  }

  &.odd {
    border-bottom: 1px solid $orange;

    a {
      color: $blue-light-txt;

      &:hover {
        color: $blue-light-txt;
      }
    }
  }

  &:hover {
    //background-color: $gray-bg;
  }
}
.fileblock .fileblock_inner {
  width: auto;
  min-height: 80px;
}
.fileblock .filetype {
  display: block;
  float: left;
  width: 70px;
  min-height: 80px;
}
.fileblock .file_ico {
  display: block;
  float: left;
  width: 58px;
  min-height: 80px;
}
.file_doc {
  //background: url(../images/ico_doc.png) 0 5px no-repeat;
  background: url(../images/doc.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_doc {
  //background: url(../images/ico_doc_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_txt {
  background: url(../images/txt.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_txt {
  opacity: 1;
}
.file_docx {
  //background: url(../images/ico_doc.png) 0 5px no-repeat;
  background: url(../images/doc.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_docx {
  //background: url(../images/ico_doc_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_pdf {
  //background: url(../images/ico_pdf.png) 0 5px no-repeat;
  background: url(../images/pdf.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_pdf {
  //background: url(../images/ico_pdf_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_xls {
  //background: url(../images/ico_excel.png) 0 5px no-repeat;
  background: url(../images/xls.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_xls {
  //background: url(../images/ico_excel_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_ppt {
  //background: url(../images/ico_powerpoint.png) 0 5px no-repeat;
  background: url(../images/ppt.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_ppt {
  //background: url(../images/ico_powerpoint_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_zip {
  //background: url(../images/ico_zip.png) 0 5px no-repeat;
  background: url(../images/zip.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_zip {
  //background: url(../images/ico_zip_hover.png) 0 5px no-repeat;
  opacity: 1;
}
.file_png {
  background: url(../images/png.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_png {
  opacity: 1;
}
.file_jpg {
  background: url(../images/jpg.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_jpg {
  opacity: 1;
}
.file_gif {
  background: url(../images/gif.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_gif {
  opacity: 1;
}
.file_bmp {
  background: url(../images/bmp.svg) 0 10px no-repeat;
  opacity: .7;
}
.fileblock:hover .file_bmp {
  opacity: 1;
}


.fileblock .filesize {
  display: block;
  float: right;
  width: 100px;
  height: auto;
  min-height: 80px;
  line-height: 50px;
  padding: 10px;
  text-align: right;
  font-size: 16px;
  font-style: italic;
  color: $blue-light-txt;
}