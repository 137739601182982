@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* migx partners */
.maintext_inner .partners .thumbs_list li {
  width: 236px;
  height: 130px;
  margin: 0 4px 4px 0;
  background: url(../images/thumb_bg.png) center top no-repeat;
  background-size: 290px 1px;
}

.maintext_inner .partners .thumbs a img {
  min-width: 236px;
  max-width: 236px;
  min-height: 130px;
  max-height: 130px;
}

.maintext_inner .partners .thumbs a {
  display: block;
  width: 236px;
  height: 130px;
  position: relative;
  overflow: hidden;
}

.maintext_inner .partners .thumbs a .desc {
  display: block;
  width: 227px;
  height: 49px;
  line-height: 16px;
  padding: 3px 5px;
  position: absolute;
  bottom: 0;
  -webkit-animation: movebottom_submenu 6s;
  animation: movebottom_submenu 6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  opacity: 0.85;
  font-size: 13px;
  color: #fff;
  background: #000;
}

.maintext_inner .partners .thumbs a.hovered .desc {
  display: block;
  -webkit-animation: movebottom_submenu 3s;
  animation: movebottom_submenu 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.maintext_inner .partners .thumbs a:hover .desc {
  display: block;
  -webkit-animation: movetop_submenu 3s;
  animation: movetop_submenu 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}