@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* LAYOUT ****************************************************/
/* basics */
html {
  text-align: center;
  margin: 0 auto 0 auto;
  padding: 0;
  background: #fff;
}
body {
  text-align: center;
  margin: 0 auto 0 auto;
  padding: 0;
  font-family: $font-family-base;
  line-height: 140%;
  min-height: 100%;
  font-size: 16px;
  color: $font-color-base;
}
body p {
  line-height: 140%;
  margin-bottom: 20px;
}
.flex_outer {
  display: flex;
}
.flex_inner {
  width: 100%;
}
.flex_outer > .flex_inner .page-left .row {
  width: 100%;
}
.clear {
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

ul li {
  padding: 0;
  list-style: none;
}

/* basic blocks ************************************/
section {
  /*overflow-x: hidden;*/
}
section#doc-content {
  text-align: left;
}
.row_crumb_and_search {
  margin-bottom: 20px;
  .crumb {
    padding-left: 0;
  }
  .search {
    padding-right: 0;
  }
}

section#doc-above-footer {
  padding-top: 100px;
}
.row_content_and_sidebar {
  > .col-md-8 {
    padding: 0;
    border-right: 2px solid $blue-light;
  }
  .sidebar {
    padding: 0;
  }
}

.row_above_footer_boxes {
  padding: 100px 0 0 0;
  margin-bottom: 28px;

  .col-md-6 {
    padding: 0 15px 0 0;

    .doc_block {
      margin:0 0 22px 0;

      h3 {
        line-height: 44px;
        margin: 0 0 2px;
        padding: 0;
        font-weight: normal;
        text-align: center;
        color: $blue-light-txt;
        border-top: 2px solid $blue-light;
        border-bottom: 2px solid $orange;
      }
      .doc_block_inner {
        min-height: 370px;
        padding: 1.4em;
        background: #E2F8FD url(../images/above_footer_box_blue_bg.jpg) no-repeat bottom center;
      }
    }

    &:nth-of-type(2n) {
      padding: 0 0 0 15px;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(11) {
      clear: both;

      .doc_block {
        margin: 0 0 22px 0;

        h3 {
          color: $orange-txt;
          border-top: 2px solid $orange;
          border-bottom: 2px solid $blue-light;
        }

        .doc_block_inner {
          background: #FFEEC7 url(../images/above_footer_box_pink_bg.jpg) no-repeat bottom center;
        }
      }
    }
  }
}
.no-gutter.col,
.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.no-padding.col,
.no-padding.row,
.no-padding.container,
.no-padding.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.page-left {
  height: 100%;
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  padding: 0;
  text-align: right;
}
.page-right {
  height: 100%;
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  padding: 0;
  text-align: center;
}


/* HP layout ***********/
.page-title {
  min-height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 2px solid $blue-light;
}
