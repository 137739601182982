@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* NAVBAR responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// max sm width
@include media-breakpoint-down(sm) {
  // Styles
}

// max lg width
@include media-breakpoint-down(lg) {
  #topmenu {

    .navbar {

      .navbar-header {
      }
      .navbar-row {
      }
      .navbar-row-lvl-1 {

        .container {

          .navbar-collapse {

            .nav {

              > li, > li.dropdown {
                > a {
                  margin: 40px 0 0 26px;
                }
                & > a:hover, &.active > a {
                }
                &.first.active {

                  > a {
                  }
                }
                & > a:hover, & > a.home-active {
                }
                &:hover > a {
                }

                ul.dropdown-menu {

                  > li {

                    &.first {
                    }
                    a {

                      span.link-image {
                      }
                    }
                    &.active > a,
                    & a:hover {
                    }
                  }
                }
              }
            }
          }
        }
      }
      .navbar-row-lvl-2 {
        background: url(../images/navbar-row-lvl-2-bg-lg.png) center top no-repeat;

        .container {
        }
        .navbar-collapse {
          .navbar-row-lvl-2-box {

            .navbar-row-lvl-2-top {
            }
            .navbar-row-lvl-2-bottom {
              .nav {

                > li {
                  > a {
                    font-size: 18px;
                    &.alink_10 {
                      font-size: 18px;
                    }
                    span.nav-item {
                      padding: 3px 20px;
                    }
                    span.link-image {
                    }
                  }
                  &.active > a,
                  &:hover > a,
                  & a:hover {

                    span.link-image {
                    }
                  }
                  &:hover > a,
                  & > a:hover {
                  }
                  ul.dropdown-menu {
                    float: right;
                    left: unset !important;
                    right: 0 !important;

                    > li {

                      a {

                        span.link-image {
                        }
                      }
                      &.active > a,
                      & a:hover {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// max md width
@include media-breakpoint-down(md) {
  #topmenu {

    .navbar {

      .navbar-header {
      }
      .navbar-row {
      }
      .navbar-row-lvl-1 {

        .container {

          .navbar-collapse {

            .nav {

              > li, > li.dropdown {
                > a {
                  font-size: 15px;
                  margin: 40px 0 0 20px;
                }
                & > a:hover, &.active > a {
                }
                &.first.active {

                  > a {
                  }
                }
                & > a:hover, & > a.home-active {
                }
                &:hover > a {
                }

                ul.dropdown-menu {

                  > li {

                    &.first {
                    }
                    a {

                      span.link-image {
                      }
                    }
                    &.active > a,
                    & a:hover {
                    }
                  }
                }
              }
            }
          }
        }
      }
      .navbar-row-lvl-2 {
        background: url(../images/navbar-row-lvl-2-bg-md.png) center top no-repeat;

        .container {
        }
        .navbar-collapse {
          .navbar-row-lvl-2-box {

            .navbar-row-lvl-2-top {
            }
            .navbar-row-lvl-2-bottom {
              .nav {

                > li {
                  > a {
                    font-size: 15px;
                    &.alink_10 {
                      font-size: 15px;
                    }

                    span.nav-item {
                      padding: 3px 10px;
                    }
                    span.link-image {
                    }
                  }
                  &.active > a,
                  &:hover > a,
                  & a:hover {

                    span.link-image {
                    }
                  }
                  &:hover > a,
                  & > a:hover {
                  }
                  ul.dropdown-menu {
                    float: right;
                    left: unset !important;
                    right: 0 !important;

                    > li {

                      a {

                        span.link-image {
                        }
                      }
                      &.active > a,
                      & a:hover {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
  // Styles
}
