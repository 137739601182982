@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* breadcrumb ******************************/
.breadcrumb {
  padding: .5rem 0 .5rem 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;

  .breadcrumb_item {
    margin-right: .5rem;
    text-transform: lowercase;
    line-height: 26px;

    + .breadcrumb_item {
      color: $gray-txt;
      padding-left: 0;

      &:before {
        display: inline-block;
        padding-right: .5rem;
        color: $orange;
        content: ">";
      }
    }

    a {
      text-decoration: none;
      color: $blue-light-txt;
      border-bottom: 1px solid $blue-light-txt;

      &:hover {
        color: $orange-txt;
        border-bottom: 1px solid white;
      }
    }
  }
}