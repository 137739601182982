@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* --------------------------------

TIMETABLES

-------------------------------- */
.cd-schedule {
  position: relative;
  z-index: 1000;
  margin: 0 0 50px 0;
  font-size: 12px;
  &::before {
    /* never visible - this is used in js to check the current MQ */
    content: 'mobile';
    display: none;
  }

  .timeline {
    display: none;
  }
  .events {
    position: relative;
    z-index: 1;

    .events-group {
      margin-bottom: 30px;
      &:nth-of-type(2n) {
        .single-event[data-event="event-1"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light;
          &:hover {
            background: $gray-bg-dark;
          }
        }
        .single-event[data-event="event-2"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-1;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-1;
          }
        }
        .single-event[data-event="event-3"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-2;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-2;
          }
        }
        .single-event[data-event="event-4"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-3;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-3;
          }
        }
        .single-event[data-event="event-5"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-4;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-4;
          }
        }
        .single-event[data-event="event-6"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-5;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-5;
          }
        }
        .single-event[data-event="event-7"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-6;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-6;
          }
        }
        .single-event[data-event="event-8"] {
          /* this is used to set a background color for the event and the modal window */
          background: $blue-light-darker-7;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $blue-light-darker-7;
          }
        }
      }

      .top-info {
        width: 100%;
        padding: 0 5%;

        & > span {
          display: inline-block;
          line-height: 1.2;
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
      & > ul {
        position: relative;
        padding: 0 5%;
        /* force its children to stay on one line */
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        &::after {
          /* never visible - used to add a right padding to .events-group > ul */
          display: inline-block;
          content: '-';
          width: 1px;
          height: 100%;
          opacity: 0;
          color: transparent;
        }

        .single-event {
          /* force them to stay on one line */
          -ms-flex-negative: 0;
          flex-shrink: 0;
          float: left;
          height: 150px;
          width: 70%;
          max-width: 300px;
          box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
          margin-right: 20px;
          -webkit-transition: opacity .2s, background .2s;
          transition: opacity .2s, background .2s;
          &:last-of-type {
            margin-right: 5%;
          }

          a {
            display: block;
            height: 100%;
            padding: .8em;
            text-decoration: none;

            .event-name, .event-date {
              display: block;
              color: $gray-txt-dark;
              font-weight: normal;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-style: normal;
              font-size: inherit;

              -webkit-transition: font-size .4s;
              -moz-transition:font-size .4s; /* Firefox 4 */
              -o-transition:font-size .4s;
              transition: font-size .4s;
            }
            .event-name {
              font-size: 1.2em;
            }
            .event-date {
              /* they are not included in the the HTML but added using JavScript */
              font-size: 1.0em;
              opacity: .7;
              line-height: 1.2;
              margin-bottom: 10px;
            }
          }
        }
        .single-event[data-event="event-1"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange;
          }
        }
        .single-event[data-event="event-2"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-1;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-1;
          }
        }
        .single-event[data-event="event-3"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-2;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-2;
          }
        }
        .single-event[data-event="event-4"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-3;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-3;
          }
        }
        .single-event[data-event="event-5"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-4;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-4;
          }
        }
        .single-event[data-event="event-6"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-5;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-5;
          }
        }
        .single-event[data-event="event-7"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-6;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-6;
          }
        }
        .single-event[data-event="event-8"] {
          /* this is used to set a background color for the event and the modal window */
          background: $orange-darker-7;
          &:hover {
            background: $gray-bg-dark;
          }

          .header-bg {
            /* this is used to set a background color for the event and the modal window */
            background: $orange-darker-7;
          }
        }
      }
    }
  }
  .event-modal {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    height: 100%;
    width: 20%;
    visibility: hidden;
    /* Force Hardware acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: visibility .4s, -webkit-transform .4s;
    transition: visibility .4s, -webkit-transform .4s;
    transition: transform .4s, visibility .4s;
    transition: transform .4s, visibility .4s, -webkit-transform .4s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);

    .header {
      position: relative;
      height: 90px;
      /* vertically center its content */
      display: table;
      width: 25%;

      .content {
        position: relative;
        z-index: 3;
        /* vertically center inside its parent */
        display: table-cell;
        vertical-align: middle;
        padding: 1.2em;

        .event-day {
          display: none;
          margin: 0 0 5px 0;

          -webkit-transition: display .4s;
          -moz-transition: display .4s; /* Firefox 4 */
          -o-transition: display .4s;
          transition: display .4s;
        }
        .event-date {
          display: block;
          line-height: 14px;
          margin-bottom: 10px;

          -webkit-transition: margin .4s;
          -moz-transition: margin .4s; /* Firefox 4 */
          -o-transition: margin .4s;
          transition: margin .4s;
        }
        .event-name {
          font-size: 1.0em;
          font-style: normal;
          line-height: 1.2em;
          -webkit-transition: font-size .4s;
          -moz-transition: font-size .4s; /* Firefox 4 */
          -o-transition: font-size .4s;
          transition: font-size .4s;
        }
      }
      .header-bg {
        /* these are the morphing backgrounds - visible on desktop only */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        -webkit-transform-origin: top center;
        -ms-transform-origin: top center;
        transform-origin: top center;
        background: $orange-darker-2;
      }
    }
    .body {
      position: relative;
      width: 100%;
      /* 70px is the .header height */
      height: calc(100% - 70px);
      overflow: hidden;

      .event-info {
        position: relative;
        z-index: 2;
        line-height: 1.4;
        height: 100%;
        overflow: hidden;

        & > div {
          overflow: auto;
          height: 100%;
          padding: 1.2em;

          -webkit-transition: padding .4s;
          -moz-transition: padding .4s; /* Firefox 4 */
          -o-transition: padding .4s;
          transition: padding .4s;
        }
        .lesson_data {
          padding: 5px 0;
        }
      }
      .body-bg {
        /* these are the morphing backgrounds - visible on desktop only */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: white;
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
      }
    }
    .close {
      /* this is the 'X' icon */
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0.1);
      /* replace text with icon */
      color: transparent;
      white-space: nowrap;
      text-indent: 100%;
      height: 90px;
      width: 90px;
      font-size: 0;
      &::before, &::after {
        /* these are the two lines of the 'X' icon */
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 22px;
        background: white;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      &::before {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
      &::after {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }
  }
  .event-modal.active {
    .header {
      .content {
        .event-name {
          font-size: 2.0em;
        }

        .event-day {
          display: block;
        }

        .event-date {
          margin: 0 0 10px 0;
        }
      }
    }
    .body {
      .event-info {
        & > div {
          padding: 1.2em;
        }
      }
    }
  }
  .event-modal.no-transition {
    -webkit-transition: none;
    transition: none;

    .header {
      .header-bg {
        -webkit-transition: none;
        transition: none;
      }
    }
    .body {
      .body-bg {
        -webkit-transition: none;
        transition: none;
      }
    }
  }
  .event-modal[data-event="event-2"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-1;
      }
    }
  }
  .event-modal[data-event="event-3"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-2;
      }
    }
  }
  .event-modal[data-event="event-4"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-3;
      }
    }
  }
  .event-modal[data-event="event-5"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-4;
      }
    }
  }
  .event-modal[data-event="event-6"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-5;
      }
    }
  }
  .event-modal[data-event="event-7"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-6;
      }
    }
  }
  .event-modal[data-event="event-8"] {
    .header {

      .header-bg {
        /* this is used to set a background color for the event and the modal window */
        background: $orange-darker-7;
      }
    }
  }
  .cover-layer {
    /* layer between the content and the modal window */
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .4s, visibility .4s;
    transition: opacity .4s, visibility .4s;
  }
}
.cd-schedule.loading {
  .events {
    .single-event {
      /* the class .loading is added by default to the .cd-schedule element
         it is removed as soon as the single events are placed in the schedule plan (using javascript) */
      opacity: 0;
    }
  }
}
.cd-schedule.modal-is-open {
  .event-modal {
    /* .modal-is-open class is added as soon as an event is selected */
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;

    .body {
      .event-info {
        & > div {
          /* smooth scroll on iOS touch devices */
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
  .cover-layer {
    opacity: 1;
    visibility: visible;
  }
}