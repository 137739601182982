@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* mxcalendar ******************************************/

/* event future/past switcher/filter */
#eventsFilter {
  width: 100%;
  height: auto;
  margin: 20px 0 !important;
  padding-right: 50px;
  border-bottom: 2px solid $orange;

  .switch {
    display: block;
    float: right;
    width: auto;
    height: 40px;
    margin: 2px 0 0 2px !important;
    padding: 0 !important;
    overflow: hidden;
    text-align: center;
    background: none !important;

    a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 40px;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      text-decoration: none;
      font-weight: normal;
      color: black;
      border: 0;
      border-radius: 0 !important;
      background: none;
    }

    &:hover a {
      color: white;
      background: $blue-light;
    }

    &.active-cls a {
      color: white;
      background: $orange;
    }

    &:hover a {
      color: white;
      background: $blue-light;
    }
  }
}