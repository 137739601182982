@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* LAYOUT responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// min 1540px width
@media (min-width: 1540px) {
  html {
    background: url(../images/side_artifacts_right.png) right top no-repeat;
  }
  body {
    background: url(../images/side_artifacts_left.png) left top no-repeat;
  }
  .navbar {
    background: url(../images/top_artifacts.png) center 260px no-repeat;
  }
  #doc-content {
    background: url(../images/top_artifacts.png) center -20px no-repeat;
  }
}

// max sm width
@include media-breakpoint-down(sm) {
  .row_content_and_sidebar {

    > .col-md-8 {
      border-right: 0;
    }
  }

  .row_above_footer_boxes {
    padding: 50px 0 0 0;

    .col-md-6 {
      padding: 0;

      .doc_block {
      }

      &:nth-of-type(2n) {
        padding: 0;
      }
    }
  }
}

// max md width
@include media-breakpoint-down(md) {
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
  // Styles
}