@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* LAYOUT ****************************************************/
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../../../../../../node_modules/bootstrap/scss/modal";

// import bootstrap
//@import '../../../themeBootstrapOverides/scss/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
//@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css';
//@import '~bootstrap-select/sass/bootstrap-select.scss';
//@import '~magnific-popup/src/css/main.scss';
