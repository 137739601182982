// custom variables which overwrites bootstrap variables

//## Grid variables
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1260px
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px
);


//## Basic Font styles
$font-family-base:    Georgia, Times New Roman, arial, verdana, Helvetica, serif !default;
$font-size-base:      1rem !default; // 16px
$font-color-base:     #444444 !default;
$font-color-error:    #FF0000 !default;


//## Basic color palette.
$orange:              #FFC744 !default;
$orange-darker-1:     #FFBE28 !default;
$orange-darker-2:     #FFBA17 !default;
$orange-darker-3:     #FBAF00 !default;
$orange-darker-4:     #DF9C00 !default;
$orange-darker-5:     #C48900 !default;
$orange-darker-6:     #CB8E00 !default;
$orange-darker-7:     #B37D00 !default;
$orange-txt:          #F7AC00 !default;

$blue-light:          #9EE8F7 !default;
$blue-light-lighter-1:#CEF3FB !default;
$blue-light-lighter-2:#E8FAFD !default;
$blue-light-darker-1: #87E2F5 !default;
$blue-light-darker-2: #76DDF3 !default;
$blue-light-darker-3: #60D7F0 !default;
$blue-light-darker-4: #44D0EE !default;
$blue-light-darker-5: #14B9DC !default;
$blue-light-darker-6: #13AFD0 !default;
$blue-light-darker-7: #119AB7 !default;
$blue-light-txt:      #11ACCD !default;

$gray-txt:            #808080 !default;
$gray-txt-dark:       #444444 !default;
$gray-bg:             #ECECFB !default;
$gray-bg-dark:        #C8C8C8 !default;


//## heading sizes
$h1-font-size:                  $font-size-base * 2.5 !default; // 40px
$h2-font-size:                  $font-size-base * 1.875 !default; // 30px
$h3-font-size:                  $font-size-base * 1.375 !default; // 22px
$h4-font-size:                  $font-size-base * 1.25 !default; // 20px
$h4-font-size-sidebar:          $font-size-base * 1.375 !default; // 22px
$h4-font-size-sidebar-bigger:   $font-size-base * 1.75 !default; // 28px
$h5-font-size:                  $font-size-base * 1.125 !default; // 18px
$h6-font-size:                  $font-size-base !default; // 16px

//## custom font sizes
//$h1-font-20:        $font-size-base * 1.25 !default; // 20px

//## breadcrumb
$breadcrumb-divider:       quote(">") !default;
$breadcrumb-divider-color: $orange-txt !default;