@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* sidebar-menu *************************************************/
.right-menu, .left-menu {
    margin: 0 0 20px 0;
    padding: 10px 20px 30px 20px;
    border-bottom: 2px solid $blue-light;
    ul {
        margin: 0 auto;
        padding: 0;
        width: 100%;

        li {
            display: block;
            width: 100%;
            height: auto;
            min-height: 50px;
            line-height: 50px;

            a {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
                line-height: 50px;
                text-decoration: none;
                color: $font-color-base;
                background: $blue-light;

                span:not(.link-image) {
                    display: block;
                    position: relative;
                    left: -5px;
                    width: calc(100% - 5px);
                    height: auto;
                    min-height: 50px;
                    margin: 0 0 2px 0;
                    padding: 0 15px 0 35px;
                    color: $font-color-base;
                    background: url(../images/arrow_l_gold.png) left center no-repeat;
                }

                span.link-image {
                    display: none;
                    //width: 25px;
                    //height: 30px;
                    //position: absolute;
                    //z-index: 5;
                    //top: 15px;
                    //left: -4px;
                    //background: url(../images/arrow_l_gold.png) left center no-repeat;
                }

                &:hover {
                    background: $orange;

                    span:not(.link-image) {
                        padding: 0 15px 0 45px;
                        background: url(../images/arrow_l_blue.png) 2px center no-repeat;
                    }
                }
            }

            &.active {

                a {
                    background: $orange;

                    span:not(.link-image) {
                        padding: 0 15px 0 45px;
                        background: url(../images/arrow_l_blue.png) 2px center no-repeat;
                    }
                }
            }
        }
    }
}