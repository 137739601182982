@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* search ******************************/
/* search form */
#searchform {

  form#filterform {
    display: block;
    margin: 0 auto;
    max-width: 300px;

    .form_input_txt {
      display: block;
      float: left;
      height: 40px;
      width: 224px;
      margin: 0 20px 0 0;
      padding: 0 5px;
      color: $gray-txt;
      font-style: italic;
      background-color: white;
      border: 0;
      border-top: 2px solid $orange;
      border-bottom: 2px solid $orange;

      &:focus {
        outline: none;
      }
    }
    .form_submit {
      height: 54px;
      width: 54px;
      line-height: 54px;
      position: relative;
      //z-index: 98;
      //right: 0;
      top: -6px;
      margin: auto;
      padding: 0;
      font-size: 1px;
      color: transparent;
      border: 0;
      background-color: transparent;
      background-image: url(../images/ico_search.png);
      -webkit-background-image: url(../images/ico_search.png);
      -webkit-background-origin: content;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 54px 54px;

      &:focus {
        outline: none;
      }
    }
  }
}