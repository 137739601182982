@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* dl-menu *************************************************/
@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon.eot');
    src:url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Common styles of menus */

.dl-menuwrapper {
    width: 100%;
    max-width: 100%;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%;
}

.dl-menuwrapper:first-child {
    margin:0 auto;
}

.dl-menuwrapper button {
    float: right;
    margin: 0;
    background: #211E1F;
    border: none;
    width: 48px;
    height: 45px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
}
.page-right .dl-menuwrapper button {
    margin: 0 50px 0 0;
}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
    background: $orange;
}

.dl-menuwrapper button:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fff;
    top: 10px;
    left: 16%;
    box-shadow:
            0 10px 0 #fff,
            0 20px 0 #fff;
}

.dl-menuwrapper ul {
    text-align: left;
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.dl-menuwrapper li {
    position: relative;
    border-bottom: 1px solid #fff;
}

.dl-menuwrapper li a {
    display: block;
    position: relative;
    padding: 15px;
    font-size: 20px;
    line-height: 22px;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    color: $font-color-base;
    outline: none;
}
.dl-menuwrapper li a:hover,
.dl-menuwrapper li.active > a {
    background: $blue-light;
}

.no-touch .dl-menuwrapper li a:hover {
    background: $blue-light;
}

.dl-menuwrapper li.dl-back > a {
    padding-left: 40px;
    color: #ffffff;
    background: rgba(0,0,0,0.2);
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
    position: absolute;
    top: 2px;
    line-height: 50px;
    font-family: 'icomoon';
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "\ea1c";
}

.dl-menuwrapper li.dl-back:after {
    left: 10px;
    color: #ffffff;
    opacity: .5;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
    right: 10px;
    color: rgba(0,0,0,0.15);
}

.dl-menuwrapper .dl-menu {
    margin: 70px 0 0 0;
    position: absolute;
    width: calc(100% + 0px);
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
    transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
    display: none;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
    display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-2 {
    -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
    animation: MenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimOut2 {
    0% { }
    100% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut2 {
    0% { }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
}

.dl-menu.dl-animate-in-2 {
    -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
    animation: MenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
    -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
    animation: SubMenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
    -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
    animation: SubMenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
}


/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
    display: block;
}

.no-js .dl-menuwrapper li.dl-back {
    display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0,0,0,0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
    content: '';
}
