@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* BREADCRUMBS responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";



// max md width
@include media-breakpoint-down(sm) {
  .breadcrumb {
    display: none;
  }
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
}

// min md width
@include media-breakpoint-up(md) {
}