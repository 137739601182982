@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* SEARCH responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// min sm width
@include media-breakpoint-up(sm) {
}

// max md width
@include media-breakpoint-down(md) {
  #searchform {

    form#filterform {

      .form_input_txt {
        width: calc(100% - 74px);
      }

      .form_submit {

      }
    }
  }
}

// min md width
@include media-breakpoint-up(md) {
}

// min lg width
@include media-breakpoint-up(lg) {
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
}