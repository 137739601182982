@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* ANIMATION keyframes */
/* partner description MOVE TOP */
/* Chrome, Safari, Opera */
@-webkit-keyframes movetop_submenu {
  0% {
    left: -237px;
  }
  25% {
    left: 0;
  }
  50% {
    left: 0;
  }
  75% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

/* Standard syntax */
@keyframes movetop_submenu {
  0% {
    left: -237px;
  }
  25% {
    left: 0;
  }
  50% {
    left: 0;
  }
  75% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

/* partner description MOVE BOTTOM */
/* Chrome, Safari, Opera */
@-webkit-keyframes movebottom_submenu {
  0% {
    left: 0;
  }
  25% {
    left: -237px;
  }
  50% {
    left: -237px;
  }
  75% {
    left: -237px;
  }
  100% {
    left: -237px;
  }
}

/* Standard syntax */
@keyframes movebottom_submenu {
  0% {
    left: 0;
  }
  25% {
    left: -237px;
  }
  50% {
    left: -237px;
  }
  75% {
    left: -237px;
  }
  100% {
    left: -237px;
  }
}

.show_transition {
  visibility: visible;
  transition: visibility .5s linear;
}
.hide_transition {
  visibility: hidden;
}


/* gallery thumb */
@keyframes galleryImgHover {
  from { bottom: 0; }
  to { bottom: -60px; }
}
@-webkit-keyframes galleryImgHover {
  from { bottom: 0; }
  to { bottom: -60px; }
}
@keyframes galleryImgHoverOut {
  from { bottom: -60px; }
  to { bottom: 0 }
}
@-webkit-keyframes galleryImgHoverOut {
  from { bottom: -60px; }
  to { bottom: 0 }
}