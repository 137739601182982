@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* pagination ******************************************/
.pageList {
  width: 100%;
  height: auto;
  margin: 20px 0 !important;
  border-top: 2px solid $blue-light;
}
.pagination {
  display: block;
  clear: both;
  width: 100%;
  height: auto;
  min-height: 50px;
  padding: 0 !important;
  margin: 2px 0 0 0 !important;
  border-radius: 0;
}
.pagination li {
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  margin: 0 2px 2px 0 !important;
  padding: 0 !important;
  overflow: hidden;
  text-align: center;
  background: none !important;
}
.pagination li a {
  display: block;
  float: left;
  position: static;
  width: 100%;
  height: 100%;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: bold;
  color: $blue-light-txt;
  border: 0;
  border-radius: 0 !important;
  background: $gray-bg;
}
.pagination li a:hover,
.pagination li:hover a,
.pagination li a.active,
.pagination li.active a {
  text-decoration: none;
  background-color: $orange;
  color: #fff;
}
.pagination li.control a {
  font-size: 1px;
  color: #009FE4;
}
.pagination li.first a,
.pagination li.last a {
  background-image: url(../images/arrow_r_doubleblue_full.png);
  background-position: center top;
  background-repeat: no-repeat;
}
.pagination li.prev a,
.pagination li.next a {
  background-image: url(../images/arrow_r_blue_full.png);
  background-position: center top;
  background-repeat: no-repeat;
}
.pagination li.prev a,
.pagination li.first a{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.pagination li.first a:hover,
.pagination li.prev a:hover,
.pagination li.next a:hover,
.pagination li.last a:hover {
  background-color: $orange;
}
.pager_counter {
  width: 100%;
  height: 20px;
  text-align: right;
  font-style: italic;
  font-size: 14px;
  color: #ddc410;
}