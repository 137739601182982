@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* basic typographhy ****************************************************/

/* basic ************************/
hr {
}
a {
  color: $orange-txt;
  text-decoration: underline;
}
a:hover {
  color: $blue-light-txt;
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
}
a {
  img {
    border: 0;
  }
}
ul {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
.italic {
  font-style: italic;
}
.separator {
}
.blue {
  color: #009FE4;
}
.nouppercase {
  text-transform: none !important;
}
.maintext_inner {
  p {
    display: block;
    clear: both;
    line-height: 140%;
    margin: 0 0 15px 0;
    padding: 0 0 0 0;
  }
  img {
  }
  iframe {
    display: block;
    clear: both;
    margin: 0;
  }
}


/* headings ************************/
#home {
  h1 {
    display: none;
  }
}
h1 {
  display: block;
  margin: 0;
  padding: 4px 0 0 0;
  font-size: $h1-font-size;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  color: #000;
}
h2 {
  display: block;
  clear: both;
  width: 100%;
  height: auto;
  line-height: 44px;
  margin: 0;
  padding: 0;
  font-size: $h2-font-size;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  color: $orange-txt;
}
.rss {
  h2 {
    width: 219px;
    margin: 0 0 5px 0;
    padding: 0 15px;
    font-size: $h2-font-size;
  }
}
h3 {
  display: block;
  clear: both;
  width: 100%;
  line-height: 44px;
  margin: 0 0 15px 0;
  padding: 0;
  font-size: $h3-font-size;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: $blue-light-txt;
  border-bottom: 2px solid $orange;

  &.link_heading {
    border: none;
  }

  &.no_link_heading {
    border: none;
    border-bottom: 2px solid $orange;
  }

  a {
    display: block;
    clear: both;
    width: 100%;
    line-height: 44px;
    color: $blue-light-txt;
    text-decoration: none;
    border-bottom: 2px solid $orange;

    &:hover {
      text-decoration: none;
      border-bottom: 2px solid $blue-light;
    }
  }
}
h4 {
  display: block;
  clear: both;
  margin: 0 0 10px 0;
  font-size: $h4-font-size;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: $orange-txt;
  border-bottom: 1px solid $blue-light;
}
h5 {
  display: block;
  clear: both;
  margin: 0 0 10px 0;
  font-size: $h5-font-size;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: $blue-light-txt;
  border-bottom: 1px solid $orange;
}
.maintext_inner {
  h2 {
    width: 100%;
    height: auto;
    line-height: 42px;
    background: none;
  }
}
.content_block {
  h3 {
    width: 100%;
    height: auto;
    line-height: 40px;
    background: none;
    font-size: $h3-font-size;
    font-weight: normal;

    a {
      color: #8BC53F;
    }
  }
}
.doc_block {
  .maincontent {
    h3 {
      margin: 25px 0;
    }
    h4 {
      margin: 20px 0;
    }
    h5 {
      margin: 20px 0;
    }
  }
}
#content {
  h2, h3, h4, h5 {
    text-align: left;
  }
  h3 {
    text-transform: none;
  }
}
#content_page {
  h2, h3, h4, h5 {
    text-align: left;
  }
}
.section-heading {
  clear: both;
  margin: 5px 0 30px 0;
  text-align: center;

  h2 {
    display: inline-flex;
    text-align: center !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $h2-font-size;
    color: #3FA5B4;

    span {
      &.heading-text {
        width: auto;
        white-space: nowrap;
        padding:3px 30px;
        background: none
      }
    }
  }
  span {
    width: 100%;
    padding: 3px 0;
  }
}
.sidebar {
  h3 {
    display: block;
    clear: both;
    width: 100%;
    line-height: 44px;
    text-decoration: none;
    text-align: center;
    font-size: $h3-font-size;
    color: $blue-light-txt;
    border-bottom: 2px solid $orange;
  }
  h4 {
    display: block;
    clear: both;
    width: 100%;
    line-height: 26px;
    min-height: 34px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    font-size: $h4-font-size-sidebar;
    color: $orange-txt;
    border-bottom: 2px solid $blue-light;
    &.list_item_heading {
      font-size: ($font-size-base * 1.125) !important;
    }
    &.h4_sidebar_box_heading {
      line-height: 26px;
      font-size: ($h4-font-size * 1.1);
      padding:0 20px 18px 20px;
    }

    a {
      padding: 0 20px;
      text-decoration: none;
    }
  }
}
footer {
  h3 {
    font-size: $h3-font-size;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-transform: none;
    color: $font-color-base;
  }
}
h3 {
  &.modal-title {
    border: 0 !important;
  }
  &.modal-title.error {
    color: $font-color-error;
  }
}


/* colored text ************************/
.orange-txt {
  color: $orange-txt;
}
.blue-txt {
  color: $blue-light-txt;
}
.orange-bold-txt {
  font-weight: bold;
  color: $orange-txt;
}
.blue-bold-txt {
  font-weight: bold;
  color: $blue-light-txt;
}


/* ordered and unordered lists ************************/
.contenttext {
  ul {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
    &:not(.dropdown-menu):not(.downloads_list):not(.thumbs_list) {
      & > li {
        display: block;
        height: auto;
        line-height: 135%;
        margin: 0 0 10px 0;
        padding: 0 0 0 20px;
        list-style: none;
        /* images are bigger (because of pixel density for some browsers), we need to set appropriate size */
        -webkit-background-size: 10px 10px;
        -moz-background-size: 10px 10px;
        -o-background-size: 10px 10px;
        background-size: 10px 10px;
        -webkit-background-origin: border-box;
        -moz-background-origin: border-box;
        -o-background-origin: border-box;
        background-origin: border-box;
        background-position: left 6px;
        background-repeat: no-repeat;
        background-image: url(../images/square_10x10_orange.gif);
      }
    }
  }
  ol {
    display: block;
    margin: 0 0 20px 20px;
    padding: 0;
  }
}
.maintext_inner {
  ul {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
    &:not(.dropdown-menu), &:not(.downloads_list), &:not(.thumbs_list) {
      & > li {
        display: block;
        height: auto;
        line-height: 135%;
        margin: 0 0 10px 0;
        padding: 0 0 0 20px;
        list-style: none;
        /* images are bigger (because of pixel density for some browsers), we need to set appropriate size */
        -webkit-background-size: 10px 10px;
        -moz-background-size: 10px 10px;
        -o-background-size: 10px 10px;
        background-size: 10px 10px;
        -webkit-background-origin: border-box;
        -moz-background-origin: border-box;
        -o-background-origin: border-box;
        background-origin: border-box;
        background-position: left 6px;
        background-repeat: no-repeat;
        background-image: url(../images/square_10x10_orange.gif);
      }
    }

    li {
      height: auto;
      line-height: 135%;
      margin: 0 0 10px 0;
    }
  }
  ol {
    display: block;
    margin: 0 0 20px 20px;
    padding: 0;

    li {
      height: auto;
      line-height: 135%;
      margin: 0 0 10px 0;
    }
  }
}
.doc-list {
  ul {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
    &:not(.dropdown-menu), &:not(.downloads_list), &:not(.thumbs_list) {
      & > li {
        display: block;
        height: auto;
        line-height: 135%;
        margin: 0 0 10px 0;
        padding: 0 0 0 20px;
        list-style: none;
        /* images are bigger (because of pixel density for some browsers), we need to set appropriate size */
        -webkit-background-size: 10px 10px;
        -moz-background-size: 10px 10px;
        -o-background-size: 10px 10px;
        background-size: 10px 10px;
        -webkit-background-origin: border-box;
        -moz-background-origin: border-box;
        -o-background-origin: border-box;
        background-origin: border-box;
        background-position: left 6px;
        background-repeat: no-repeat;
        background-image: url(../images/square_10x10_orange.gif);
      }
    }
  }
}

/* ul list with icons ************************/
ul {
  &.icons_list {
    display: block;
    margin: 10px 0 20px 0;
    padding: 0;

    li {
      display: block;
      height: auto;
      line-height: 140%;
      margin: 0 0 10px 0;
      padding: 0 0 0 0;
      list-style: none;
      font-family: Georgia, Times New Roman, arial, verdana, Helvetica, serif;
      font-size: 24px;

      i {
        display: block;
        float: left;
        width: 36px;
        line-height: 136%;
        font-size: 100%;
        color: #fff;
      }
    }
  }
}
#fsocial {
  ul {
    &.icons_list {

      li {
        display: block;
        float: left;
        margin: 0 20px 10px 0;
        text-align: left;

        i {
          width: auto;
          line-height: 136%;
          font-size: 136%;
          color: #fff;
        }
      }
    }
  }
}

/* contact ul list ************************/
ul {
  &.ul_contact {
    display: block;
    width: auto;
    height: auto;
    min-height: 60px;
    margin: 0 0 8px 0;
    padding: 0 0 10px 190px !important;
    border-bottom: 2px solid #DDDDB9;
  }
}