@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* CONTENT ****************************************************/
/* #content block */
#doc-content {
  text-align: left;

  .maintext {
    padding: 0;

    .maintext_inner {
      line-height: 140%;
      padding: 0 0 0 0;

      .introtext {
        margin: 0 0 20px;

        p {
          //font-weight: bold;
        }
      }

      .img-main-left {
        border-top: 2px solid $orange;
        padding-top: 2px;
      }

      .img-main-wide {
        border-top: 2px solid $orange;
        padding-top: 2px;
      }

      .circle {
        border-top: 0;

        a {
          img {
            border-radius: 50%;
          }
        }
        img {
          border-radius: 50%;
        }
      }

      .description {
        margin: 0 0 10px 0;
        font-family: $font-family-base;
        font-weight: bold;
        color: $blue-light-darker-5;
      }

      .maincontent {
        margin: 0 0 30px 0;
      }

      .maintext_components {

        .sisea-results-list {
          padding-right: 50px;
        }
      }
    }
  }

  .doc-list, .sisea-results-list {
    padding-right: 50px;

    .month_heading {
      display: block;
      position: absolute;
      right: 50px;
      float: right;
      clear: both;
      width: 56px;
      height: 70px;
      margin-top: 10px;
      padding-top: 15px;
      text-decoration: none;
      text-align: center;
      font-style: italic;
      background: url(../images/small_calc_ico_bg.png) no-repeat top left;

      .number_calc {
        font-size: 140%;
        font-weight: bold;
      }
    }

    .doc_block {
      padding: 0 0 60px 0;

      .doc_block_inner {

        .doc_block_txt {

          .date {
            display: block;
            margin: 0 0 15px 0;
            font-style: italic;
            color: $gray-txt;
          }

          .introtext {
            line-height: 140%;
            margin: 0 0 15px 0;
            font-size: 100%;
            font-weight: normal;

            p {
              line-height: 180%;
              margin: 0 !important;
            }
          }

          .description {
            margin: 0 0 10px 0;
            font-family: $font-family-base;
            font-weight: bold;
            color: $blue-light-darker-5;
          }

          .img-list-left {
            border-top: 2px solid $blue-light;
            padding-top: 2px;
            margin-bottom: 20px;

            img {
              min-width: 100%;
              max-width: 100%;
              height: auto;
            }

            a {

              img {
                min-width: 100%;
                max-width: 100%;
                height: auto;
              }
            }

            &.circle {
              border-top: 0;

              a {
                img {
                  border-radius: 50%;
                }
              }
            }
          }

          .search_result {
            font-weight: bold;
            color: $orange-txt;
          }
        }
      }

      &.doc_block_partner {

        .doc_block_inner {

          .doc_block_txt {

            .img-list-left {
              border-top: 0;
            }
          }
        }
      }
    }

    .doc_block_event {
      margin-right: 80px;
    }

    .doc_block_square {
      margin-bottom: 30px;

      &:hover {
      }

      .doc_block_inner {

        .doc_block_txt {

          h3.link_heading {
            margin: 0;

            a {
              display: table;
              height: 229px;
              min-height: 229px;
              max-height: 229px;
              text-decoration: none;
              text-align: center;
              font-size: 1.3em;
              color: #fff;
              border: 0;
              background-color: #ffc744;
              transition: background-color .3s linear,color .3s linear;

              &:hover {
                color: $font-color-base;
                background-color: $blue-light;
              }

              span {
                display: table-cell;
                height: 100%;
                padding: 15px 15%;
                vertical-align: middle;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}


.link_more_outer {
  display: block;
  width: 200px;
  height: 46px;
  line-height: 35px;
  padding: 0;
  color: #8BC53F;

  .link_more {
    display: block;
    float: left;
    clear: both;
    width: auto;
    height: 35px;
    margin: 0;
    padding: 0 30px 0 0;
    line-height: 36px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-size: 34px;

    .link_text {
      font-family: $orange-txt;
      font-size: $h4-font-size;
      text-decoration: none;
      color: $orange-txt;
    }
  }

  &:hover .link_more {
    animation: linkMore .3s linear;
    -ms-animation: linkMore .3s linear;
    -moz-animation: linkMore .3s linear;
    -webkit-animation: linkMore .3s linear;
    animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
}

/* sidebar */
.sidebar {

  .sidebar-box {

    .doc_block {
      margin: 0 0 50px 0;

      .doc_image {
        margin: 0 0 20px 0;

        img {
          min-width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
      .doc_block_inner {
        padding: 10px 20px;
      }
    }
    .doc-list {
      margin: 0 0 30px 0;
      padding-right: 0 !important;

      .doc_block {
        margin: 0 0 0 0;
        padding: 0 0 30px 0 !important;

        .doc_block_inner {
          padding: 0;

          .introtext {
            padding: 0 20px;
            color: $gray-txt;

            strong {
              color: $font-color-base;
            }
          }
          .link_more_outer {
            width: 100%;
            height: auto;
            padding: 10px 0 20px 0;
            border-bottom: 2px solid $blue-light;

            .link_more {
              padding-left: 20px;

              .link_text {
                color: $blue-light-txt;
              }
            }
          }
        }
      }
    }
  }

  .right-menu, .left-menu {
  }
}

/* basic text blocks */
#content {
  width: 100%;
  height: auto;
  line-height: 18px;
  padding-right: 30px;
  text-align: left;
  font-family: $font-family-base;
}

.single .contenttext {
  line-height: 140%;
  margin: 0 0 40px 0;
  padding: 0 0 20px 0;
  text-align: left;
}
.block_txt {
  width: 570px;
  float: left;
}
.noimage .block_txt {
  width: 100%;
  float: none;
}


/* left img of document in document list */
.doc_image {
  margin: 0 0 20px 0;

  &.circle {
    border-top: 0;
  }

  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* main bgimage */
.bgimage {
  z-index:1;
  background-repeat: repeat-y;
  background-position: right top;
  background-size: 100% auto;
}

ul.ul-sloupce {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
}
