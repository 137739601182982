@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* tables still need 'cellspacing="0"' in the markup */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
table tr {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
body p {
  line-height: 120%;
  margin-bottom: 20px;
}

/* content tables */
.maintext table {
  width: 100%;
  text-align: left;
  margin: 0 0 20px 0;
}
.maintext .txt.right table {
  font-size: 14px;
}
.maintext table tr {
  background: #fff;
}
.maintext table.basic tr {
}
.maintext table tr:last-child th,
.maintext table tr:last-child td {
  border-bottom: 1px solid transparent;
}
.maintext table.basic tr:last-child th,
.maintext table.basic tr:last-child td {
  border-bottom: 1px solid transparent;
}
.maintext table {
  tr {
    th {
      padding: 5px 10px;
      vertical-align: top;
      border: 0;
      text-align: left;
      font-weight: normal;
      font-size: 18px;
      color: inherit;
      background: $blue-light-lighter-1;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      :nth-child(1) {
        text-align: left;
      }
    }

    td {
      padding: 5px 10px;
      vertical-align: top;
      text-align: left;
      border: 0;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      background: $blue-light-lighter-2;

      :nth-child(1) {
        text-align: left;
      }

      strong {
        color: inherit;
      }
    }
  }
  &.basic tr {
    th {
      border-right: 0;
      border-bottom: 1px solid #fff;
    }
    td {
      border-right: 0;
      border-bottom: 1px solid #ccc;
    }
  }
  &.popisna tr {
    th {
      width: 22% !important;
    }
    td {
      :nth-child(1) {
        width: 180px;
        color: inherit;
        background: $blue-light;
      }
    }
  }
  &.minirozvrh {
    tr {
      th {
        padding: 5px !important;
        font-size: 15px !important;
      }

      td {
        padding: 5px !important;
      }
    }
  }
}
//.maintext table.basic tr th {
//  border-right: 0;
//  border-bottom: 1px solid #fff;
//}
//.maintext table tr th:nth-child(1) {
//  text-align: left;
//}
//.maintext table.popisna tr th {
//  width: 22% !important;
//}
//.maintext table tr td {
//  padding: 5px 10px;
//  vertical-align: top;
//  text-align: left;
//  border: 0;
//  border-right: 1px solid #ccc;
//  border-bottom: 1px solid #ccc;
//  background: $blue-light-lighter-2;
//}
//.maintext table.basic tr td {
//  border-right: 0;
//  border-bottom: 1px solid #ccc;
//}
.maintext .txt.right table tr td {
  padding: 5px;
}
//.maintext table tr td:nth-child(1) {
//  text-align: left;
//}
//.maintext table.popisna tr td:nth-child(1) {
//  width: 180px;
//  color: inherit;
//  background: $blue-light;
//}
//.maintext table tr td strong {
//  color: inherit;
//}
table.clean {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  border-collapse: collapse;
  border-bottom: 1px solid transparent;

  tr {
    width: 100%;
    height: auto;
    border: 0;

    :last-child {
    }
  }
  td {
    padding: 0 10px 15px 0;
    width: auto;
    height: auto;
    border: 0;

    :first-child {
      width: 35%;
    }
  }
}
//table.clean tr {
//  width: 100%;
//  height: auto;
//  border: 0;
//}
//table.clean tr:last-child {
//}
//table.clean td {
//  padding: 0 10px 15px 0;
//  width: auto;
//  height: auto;
//  border: 0;
//}
//table.clean td:first-child {
//  width: 35%;
//}


/* timetables */
.timetable {

  .timetable_inner {

    table {
      border-collapse: collapse;
      color: $gray-txt;

      thead {

        tr {

          th {
            line-height: 60px;
            padding: 5px;
            background: $orange;
            font-size: $h4-font-size-sidebar-bigger;
            font-weight: normal !important;
            text-align: center;
            border: 2px solid #fff;

            &:nth-child(2n) {
              background: $blue-light;
            }
          }
        }
      }

      tbody {

        tr {

          td {
            line-height: 60px;
            padding: 10px 10px;
            background: $blue-light;
            font-weight: normal;
            text-align: left;
            border: 2px solid #fff;
            vertical-align: top;

            &:nth-child(2n) {
              background: $orange;
            }

            .lesson_name {
              display: block;
              font-weight: normal;
              font-size: $h3-font-size;
              text-align: center;
            }
          }

          //&:nth-child(2n) {
          //
          //  td {
          //    background: $orange;
          //
          //    &:nth-child(2n) {
          //      background: $blue-light;
          //    }
          //  }
          //}
        }
      }
    }
  }
}


/* PLAN table */
table.plan_head {
  tr {
    th {
      vertical-align: middle !important;
      background: #ffc744 !important;
      border-right: 1px solid #ccc !important;
      border-right-color: #fff !important;

      :last-child {
        border-right: 1px solid transparent !important;
      }
    }
    td {
      :last-child {
        border-right: 1px solid transparent !important;
      }
    }
  }
}
table.plan_day {
  tr {
    th {
      text-align: center !important;
      vertical-align: middle !important;
      background: #ffc744 !important;
      border-right-color: #fff !important;

      :last-child {
        border-right: 1px solid transparent !important;
      }
    }
    td {
      :last-child {
        border-right: 1px solid transparent !important;
      }
    }
    :last-child {
      th {
        border-bottom: 1px solid #ccc !important;
      }
      td {
        border-bottom: 1px solid #ccc !important;
      }
    }
  }
}