@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* hamburger button *********************/
.dl-trigger {
  float: right;

  .hamburger {
    padding: 15px 0;
    display: inline-block;
    cursor: pointer;
    transition-property: filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {

      .hamburger-box {
        .hamburger-inner {
          background-color: $blue-light-darker-4;

          &::before,
          &::after {
            background-color: $blue-light-darker-4;
          }
        }
      }
    }

    .hamburger-box {
      width: 40px;
      height: 24px;
      display: inline-block;
      position: relative;

      .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
        width: 40px;
        height: 4px;
        background-color: #ffffff;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;

        &::before,
        &::after {
          content: "";
          display: block;
          width: 40px;
          height: 4px;
          background-color: #ffffff;
          border-radius: 4px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
        }

        &::before {
          top: -10px;
        }

        &::after {
          bottom: -10px;
        }
      }
    }

    /* Spin */
    &.hamburger--spin {

      .hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
          transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
        }

        &::after {
          transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }

      &.is-active {

        .hamburger-inner {
          transform: rotate(225deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

          &::before {
            top: 0;
            opacity: 0;
            transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
          }

          &::after {
            bottom: 0;
            transform: rotate(-90deg);
            transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
    }

    /* Spin Reverse */
    &.hamburger--spin {

      .hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
          transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
        }

        &::after {
          transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }

      &.is-active {

        .hamburger-inner {
          transform: rotate(-225deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

          &::before {
            top: 0;
            opacity: 0;
            transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
          }

          &::after {
            bottom: 0;
            transform: rotate(90deg);
            transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
    }
  }
}