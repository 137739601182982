@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* footer *************************************************/
footer {
  text-align: left;
  color: $font-color-base;
  margin-top: 0;


  .footer-top [class*='col-'] {
    margin-bottom: 10px;
  }
  .footer-top {
    text-align: left;
    padding: 45px 35px;
    color: $font-color-base;
    margin-top: 0;
    background: $orange;
    border-top: 10px solid $blue-light;

    #footermenu {

      ul {
        height: auto;
        margin: 0;
        padding: 0;
        list-style-type: square;

        li {
          display: block;
          float: left;
          width: 100%;
          height: auto;
          line-height: 1.30rem;
          margin: 0 0 10px 0;
          padding: 0 0 0 20px;
          background: url(../images/square_10x10_white.gif) left 6px no-repeat;
          -webkit-background-size: 10px 10px;
          -moz-background-size: 10px 10px;
          -o-background-size: 10px 10px;
          background-size: 10px 10px;
          -webkit-background-origin: border-box;
          -moz-background-origin: border-box;
          -o-background-origin: border-box;
          background-origin: border-box;
        }

        a {
          font-size: 1.25rem;
          font-weight: normal;
          text-decoration: none;
          color: $font-color-base;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    #fsocial {

      .icons_list {

        > li {

          > a:hover {

            i {
              color: $blue-light-darker-5;
            }
          }
        }

        .btn-rss {
          display: block;
          position: relative;
          width: 28px;
          height: 28px;

          a.active {

            i {
              color: $blue-light-darker-5;
            }
          }

          .rss_menu {
            position: absolute;
            z-index: 100;
            top: 40px;
            right: 0;
            display: none;
            width: 400px;
            height: auto;
            padding: 2px 0;
            background: white;
            border-radius: 5px;

            ul {
              margin: 0;
              padding: 0;

              li {
                display: block;
                float: left;
                margin: 0;
                padding: 2px 0;
                width: 50%;
                border-bottom: 1px solid $blue-light;
                border-right: 1px solid $blue-light;

                &:last-of-type {
                  border-bottom: none;
                }

                &:nth-of-type(2n) {
                  border-right: none;
                }

                &:nth-of-type(3n) {
                  clear: both;
                }

                a {
                  display: block;
                  padding: 0 15px;
                  text-decoration: none;
                  font-size: 14px;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    overflow-x: hidden;
    text-align: left;
    padding: 20px 35px;
    color: $font-color-base;
    margin-top: 0;
    background: none;

    #copywrite {
      width: 100%;
      //margin: 0 auto;
      //padding: 0;
      font-size: 1.25rem;
      color: $font-color-base;

      .company_copyw {
        text-align: left;
        font-size: .7em;
      }

      .madeby {
        text-align: right;
        font-size: .7em;

        a {
          text-decoration: none;
          opacity: .6;
          display: block;
          float: right;
          width: 61px;
          height: 15px;
          padding: 0 0 0 10px;
          font-size: 1px;
          line-height: 1000;
          overflow: hidden;
          background: url(../images/www_logo.png) no-repeat right top;
          -webkit-background-size: 61px 15px;
          -moz-background-size: 61px 15px;
          -o-background-size: 61px 15px;
          background-size: 61px 15px;
          -webkit-background-origin: border-box;
          -moz-background-origin: border-box;
          -o-background-origin: border-box;
          background-origin: border-box;

          &:hover {
            text-decoration: underline;
            opacity: .9;
          }
        }

        span {
          display: block;
          float: right;
          padding: 0 10px 0 0;
          line-height: 15px;
        }
      }
    }
  }
}