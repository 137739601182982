@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* HEADER ****************************************************/
/* home_link */
a.home_link {
  display: block;
  float: left;
  width: 237px;
  height: 140px;
  line-height: 1000px;
  z-index: 101;
  overflow: hidden;
  text-align: left;
  color: #fff;
  background: url(../images/logo.png) no-repeat top left;
}
/* responsive */
.row_logo_hamburger {
  height: 54px;

  a.home_link_phones {
    display: block;
    float: left;
    width: calc(100% - 40px);
    height: 63px;
    margin-left: 0;
    line-height: 54px;
    z-index: 101;
    overflow: hidden;
    text-align: left;
    color: #fff;
    font-size: 36px;
    text-decoration: none;
    background: url(../images/logo_small.png) no-repeat top left;
    span {
      display: none;
    }
  }
  a.home_link_responsive span {
    display: none;
  }
}