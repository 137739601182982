@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* isLoading */
.isloading-wrapper.isloading-right {
  margin-left: 10px;
}
.isloading-overlay {
  position: absolute;
  text-align: center;
  background: #fff;
  height: 100%;
  width: 100%;
  -moz-opacity: 0.9;
  opacity: .90;
  filter: alpha(opacity=90);
  z-index: 900;
}
.isloading-overlay.isloading-show {
  line-height: 50px;
}
.isloading-overlay.isloading-wrapper {
  display: block;
  z-index: 90000;
  width: 100px;
  height: 32px;
  position: fixed;
  top:42%;
  left:50%;
  padding: 0 0 60px 0;
  margin: 0 0 0 -50px;
  background: transparent url('../images/loading.gif') no-repeat bottom center;
  line-height: 16px;
  border: 0;
  cursor: wait;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #646473;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}