@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* NAVBAR topmenu *************************************************/
#topmenu {
  padding: 0;
  z-index: 1;
  .navbar {
    padding: 0 !important;
    .navbar-header {
      z-index: 1001;
      width: 100%;
      margin-bottom: 20px;
      background: $orange;
    }
    .navbar-row {
      width: 100%;
    }
    .navbar-row-lvl-1 {
      height: 140px;
      .container {
        height: 100%;
        .navbar-collapse {
          height: 100%;
          .nav {
            height: 100%;
            > li, > li.dropdown {
              > a {
                display: block;
                margin: 70px 0 0 40px;
                padding: 25px 0 0 0;
                height: auto;
                min-height: 48px;
                font-size: 18px;
                color: $font-color-base;
                border-top: solid 2px transparent;
                text-decoration: none;
              }
              & > a:hover, &.active > a {
                border-top: solid 2px $orange;
              }
              &.first.active {

                > a {
                  border-top: solid 2px #fff;
                }
              }
              & > a:hover, & > a.home-active {
                border-top: solid 2px $orange !important;
              }
              &:hover > a {
                border-top: solid 2px $orange;
              }

              ul.dropdown-menu {
                position: absolute;
                top: 90%;
                right: 0 !important;
                left: unset;
                z-index: 1002;
                display: none;
                float: right;
                min-width: 350px;
                padding: 13px 0 0 0;
                margin: 0;
                text-align: left;
                list-style: none;
                background-clip: padding-box;
                border: 0;
                border-bottom: 2px solid $orange;
                border-radius: 0;
                background: url(../images/link-image-tmenu.png) right top no-repeat;
                > li {
                  background: white;
                  border-top: solid 1px $blue-light;
                  &.first {
                    border-top: 2px solid $orange;
                  }
                  a {
                    display: block;
                    line-height: 24px;
                    padding: 9px 10px;
                    font-size: 18px;
                    color: $font-color-base;
                    text-decoration: none;
                    span.link-image {
                      display: none;
                    }
                  }
                  &.active > a,
                  & a:hover {
                    background: $orange;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar-row-lvl-2 {
      background: url(../images/navbar-row-lvl-2-bg.png) center top no-repeat;
      .container {
      }
      .navbar-collapse {
        .navbar-row-lvl-2-box {
          position: relative;
          float: left;
          margin-left: 2px;
          width: calc(100% - 239px);
          .navbar-row-lvl-2-top {
            position: absolute;
            left: 1px;
            z-index: 1000;
            width: 100%;
            height: 16px;
            background: #fff;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%);
          }
          .navbar-row-lvl-2-bottom {
            .nav {
              width: 100%;
              min-height: 120px;
              background: $blue-light;
              > li {
                > a {
                  display: flex;
                  position: relative;
                  z-index: 999;
                  height: 100%;
                  line-height: 30px;
                  padding: 0;
                  text-decoration: none;
                  font-size: 22px;
                  color: $font-color-base;
                  border-bottom: solid 3px $blue-light;
                  &.alink_10 {
                    font-size: 28px;
                    line-height: 34px;
                    color: #fff;
                    border-bottom: 3px solid #ffc744;
                    background: #ffc744 !important;
                  }
                  span.nav-item {
                    height: auto;
                    padding: 3px 30px;
                    margin: auto; /* Important to vertical centering */
                    display: inline-block;
                    vertical-align: middle;
                  }
                  span.link-image {
                    display: none;
                  }
                }
                &.active > a,
                &:hover > a,
                & a:hover {
                  padding: 0;
                  color: $font-color-base;
                  background: #fff;
                  border-bottom: solid 3px $orange;
                  span.link-image {
                    display: block;
                    position: absolute;
                    z-index: 1001;
                    bottom: -13px;
                    width: 100%;
                    height: 13px;
                    background: url(../images/link-image.png) center top no-repeat;
                  }
                }
                &:hover > a,
                & > a:hover {
                  z-index: 1001;
                }
                &.active > a.alink_10,
                &:hover > a.alink_10,
                & a.alink_10:hover {
                  color: #444;
                  background: #ffffff !important;
                }
                ul.dropdown-menu {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  z-index: 1001;
                  display: none;
                  float: left;
                  min-width: 350px;
                  padding: 0;
                  margin: .125rem 0 0;
                  text-align: left;
                  list-style: none;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 0;
                  border-radius: 0;
                  > li {
                    background: $blue-light;
                    a {
                      display: block;
                      line-height: 20px;
                      padding: 9px 10px;
                      font-size: 18px;
                      color: $font-color-base;
                      border-bottom: solid 2px #fff;
                      text-decoration: none;
                      span.link-image {
                        display: none;
                      }
                    }
                    &.active > a,
                    & a:hover {
                      background: $orange;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}