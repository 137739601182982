@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* FOOTER responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// max sm width
@include media-breakpoint-down(sm) {
  #copywrite {
    margin: 0;
    .company_copyw {
      text-align: right;
      font-size: .7em;
      padding-bottom: 5px;
      padding-right: 0;
    }

    .madeby {
      padding-right: 0;
    }
  }
}

// max md width
@include media-breakpoint-down(lg)
{
  #fsocial {
    min-height: 300px;

    .btn-rss {

      .rss_menu {
        right: auto;
        left: 0;

      }
    }
  }

  #footermenu {
    ul {
      display: grid;
      margin-bottom: 30px;

      li {

        a {
          font-size: 1.2rem;
        }
      }
    }
  }
}

// max md width
@include media-breakpoint-down(md)
{
  #fsocial {
    min-height: 300px;

    .btn-rss {

      .rss_menu {
        right: auto;
        left: 0;
        width: 300px !important;

      }
    }
  }
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
  // Styles
}

// max xs width
@include media-breakpoint-up(xs)
{
  #footermenu {
    ul {

      li {

        a {
          font-size: 1.1rem !important;
        }
      }
    }
  }
  #footercontact {
    .icons_list {

      li {
        font-size: 1.1rem !important;
      }
    }
  }
  #fsocial {

    .btn-rss {

      .rss_menu {
        width: auto !important;
        min-width: 280px;
        max-width: 280px;

        ul {
          margin: 0;
          padding: 0;

          li {
            width: 100% !important;
            float: none !important;
            border-right: 0 !important;
          }
        }
      }
    }
  }
}