@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* CUSTOM_CLASSES responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// max sm width
@include media-breakpoint-down(sm) {
  .no-margin-sm {
    margin: 0;
  }
  .no-padding-sm {
    padding: 0;
  }
  .padding-left-35-sm {
    padding-left: 35px;
  }
  .padding-left-45-sm {
    padding-left: 45px;
  }
  .padding-right-35-sm {
    padding-right: 35px !important;
  }
  .margin-bottom-30-sm {
    margin-bottom: 30px;
  }
  .padding-bottom-30-sm {
    padding-bottom: 30px;
  }
}

// max md width
@include media-breakpoint-down(md) {
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
  .padding-left-35-md {
    padding-left: 35px;
  }
  .padding-left-45-md {
    padding-left: 45px;
  }
  .padding-right-35-md {
    padding-right: 35px !important;
  }
  .margin-bottom-30-md {
    margin-bottom: 30px;
  }
  .padding-bottom-30-md {
    padding-bottom: 30px;
  }
}

// min md width
@include media-breakpoint-up(md) {
  .padding-left-35 {
    padding-left: 35px;
  }
  .padding-left-45 {
    padding-left: 45px;
  }
  .padding-right-35 {
    padding-right: 35px !important;
  }
  .margin-bottom-30 {
    margin-bottom: 30px !important;
  }
  .padding-bottom-30 {
    padding-bottom: 30px;
  }
}