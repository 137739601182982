@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* TIMETABLES responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// min sm width
@include media-breakpoint-up(sm) {
  .cd-schedule {

    .events {

      .events-group {

        .top-info {
          padding: 0;
        }
        & > ul {
          padding: 0;

          .single-event {
            width: 20%;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

// min md width
@include media-breakpoint-up(md) {
  .cd-schedule {
    width: calc(100% - 60px);
    max-width: 1400px;
    &::after {
      clear: both;
      content: "";
      display: block;
    }
    &::before {
      content: 'desktop';
    }

    .timeline {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: 120px;

      li {
        position: relative;
        height: 120px;
        &::after {
          /* this is used to create the table horizontal lines */
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: none;
          border-top: 1px solid $gray-bg-dark;
        }
        &:last-of-type::after {
          display: none;
        }

        span {
          display: none;
        }
      }
    }
    .events {
      float: left;
      width: 100%;

      .events-group {
        width: calc(20%);
        float: left;
        border: none;
        /* reset style */
        margin: 0;
        &:not(:first-of-type) {
          width: calc(20% - 2px);
          border-left-width: 0;
          margin: 0 0 0 2px;
        }
        &:nth-of-type(2n) {
          .top-info {
            background: $blue-light;
          }
        }

        .top-info {
          /* vertically center its content */
          display: table;
          height: 120px;
          border-bottom: 2px solid #fff;
          /* reset style */
          padding: 0;
          background: $orange;

          & > span {
            /* vertically center inside its parent */
            display: table-cell;
            vertical-align: middle;
            padding: 0 .5em;
            text-align: center;
            /* reset style */
            font-weight: normal;
            font-size: $h4-font-size;
            margin-bottom: 0;
          }
        }
        & > ul {
          //height: 950px;
          /* reset style */
          display: block;
          overflow: visible;
          padding: 0;
          &::after {
            clear: both;
            content: "";
            display: none;
          }

          .single-event {
            position: absolute;
            z-index: 3;
            /* top position and height will be set using js */
            width: calc(100%);
            left: 0;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
            /* reset style */
            -ms-flex-negative: 1;
            flex-shrink: 1;
            height: auto;
            max-width: none;
            margin-right: 0;
            &:last-of-type {
              /* reset style */
              margin-right: 0;
            }

            a {
              padding: 5px;

              .event-date {
                font-size: .9em;
                line-height: 14px;
              }
              .event-name {
                font-size: 1em;
                line-height: 14px;
              }
            }
          }
          .single-event.selected-event {
            /* the .selected-event class is added when an user select the event */
            visibility: hidden;
          }
        }
      }
    }
    .event-modal {
      /* reset style */
      right: auto;
      width: auto;
      height: auto;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      will-change: transform, width, height;
      -webkit-transition: height .4s, width .4s, visibility .4s, -webkit-transform .4s;
      transition: height .4s, width .4s, visibility .4s, -webkit-transform .4s;
      transition: height .4s, width .4s, transform .4s, visibility .4s;
      transition: height .4s, width .4s, transform .4s, visibility .4s, -webkit-transform .4s;
      -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
      transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);

      .header {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;

        .content {
          /* reset style */
          display: block;
          padding: 1.2em .8em;

          .event-date {
            display: block;
          }
          .event-name {
            font-size: 1.4em;
            line-height: 1.2em;
          }
        }
        .header-bg {
          /* Force Hardware acceleration */
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          will-change: transform;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transition: -webkit-transform .4s;
          transition: -webkit-transform .4s;
          transition: transform .4s;
          transition: transform .4s, -webkit-transform .4s;
          -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
          transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
        }
      }
      .body {
        height: 100%;
        width: auto;

        .event-info {
          opacity: 0;

          & > div {
            //padding: 2em 3em 2em 2em;
          }
        }
        .body-bg {
          /* Force Hardware acceleration */
          will-change: transform;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          opacity: 0;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }
      .close {
        opacity: 0;
        width: 40px;
        height: 40px;
        background: transparent;
        &::before {
          background: #222222;
          height: 16px;
        }
        &::after {
          background: #222222;
          height: 16px;
        }
      }
    }
  }
  .cd-schedule.animation-completed {
    .event-modal {
      .close {
        /*  the .animation-completed class is added when the modal animation is completed
          the .content-loaded class is added when the modal content has been loaded (using ajax) */
        opacity: 1;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
      }
    }
  }
  .cd-schedule.content-loaded.animation-completed {
    .event-modal {
      .event-info {
        /*  the .animation-completed class is added when the modal animation is completed
          the .content-loaded class is added when the modal content has been loaded (using ajax) */
        opacity: 1;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
      }
    }
  }
  .cd-schedule.modal-is-open {
    .event-modal {
      .header {
        .content {
          .event-name {
            font-size: 2em;
          }
        }
      }
      .body {
        .body-bg {
          opacity: 1;
          -webkit-transition: -webkit-transform .4s;
          transition: -webkit-transform .4s;
          transition: transform .4s;
          transition: transform .4s, -webkit-transform .4s;
          -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
          transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
        }
      }
    }
  }
}

// min lg width
@include media-breakpoint-up(lg) {
  .cd-schedule {
    .timeline {
      li {
        :nth-of-type(2n) {
          span {
            //display: none;
          }
        }
        &::after {
          width: calc(100% + 15px);
          left: 45px;
        }

        span {
          font-weight: bold;
          display: inline-block;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
    .events {
      /* 60px is the .timeline element width */
      width: calc(100% - 0px);
      margin-left: 60px;

      .events-group {
        .top-info {
          & > span {
            font-size: $h3-font-size;
          }
        }
        & > ul  {
          .single-event {
            a {
              padding: 1.2em;

              .event-date {
                font-size: 1em;
                line-height: 14px;
              }
              .event-name {
                font-size: 1.1em;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
    .event-modal {
      .header {
        .content {
          padding: 1.2em;
        }
      }
    }
  }
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
}



// min lg width
@include media-breakpoint-up(xl) {
  .cd-schedule {

    .events {

      .events-group {

        & > ul {

          .single-event {

            a {

              .event-date {
                font-size: 1em;
                line-height: 14px;
              }
              .event-name {
                font-size: 1.2em;
                line-height: 1.2em;
              }
            }
          }
        }
      }
    }
  }
}