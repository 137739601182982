@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* gallery and migxgallery */

.images {
  &.images-lectors {
    margin: 5px 0 0 0;
  }
}
.thumbs {
  margin: 0 0 40px 0;
  padding: 0 0 20px 0;

  .thumbs_list {
    margin: 0;
    &.single-image {

    }

    .gal-item {
      margin: 0 10px 10px 0;
      float: left;
      display: block;
      padding: 0;
      list-style: none;
      &.gal-item-heads {
        margin: 0px 20px 20px 0;
        border: 0;
      }

      &:nth-of-type(4n) {
        margin: 0 0 10px 0;
      }
      &.gal-item-heads:nth-of-type(4n) {
        margin: 0 0 20px 0;
      }

      &.link_back_outer {

        .ajaxlink {
          min-height: 100%;
          min-width: 100%;
          max-height: 100%;
          max-width: 100%;
          line-height: 174px;
          text-decoration: none;
          text-align: center;
          font-size: 2em;
          color: white;
          background-color: $orange;
          transition: background-color .3s linear, color .3s linear;

          &:hover {
            color: $font-color-base;
            background-color: $blue-light;
          }

          .link_back {
            display: block;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
          }
        }
      }

      .thumb_link, .ajaxlink {
        display: block;
        position: relative;
        max-width: 184.5px;
        overflow: hidden;

        &:visited,
        &:focus {
          outline: 0;
          outline-offset: 0;
        }

        .thumb {
          margin: 0;
          overflow: hidden;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
        }

        .thumb_info {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: auto;
          min-height: 100px;
          line-height: 16px;
          padding: 15px;
          font-family: Georgia, Times New Roman, arial, verdana, Helvetica, serif;
          font-weight: normal;
          font-size: 14px;
          color: #333;
          //background: url(../images/thumb_bg.png) center top repeat;
          //background-size: 5% 5%;
          //background-color: rgba(255, 199, 68, .6);
          background-color: rgba(158, 232, 247, .6);

          animation: galleryImgHoverOut .6s ease;
          -ms-animation: galleryImgHoverOut .6s ease;
          -moz-animation: galleryImgHoverOut .6s ease;
          -webkit-animation: galleryImgHoverOut .6s ease;
          animation-iteration-count: 1;
          -ms-animation-iteration-count: 1;
          -moz-animation-iteration-count: 1;
          -webkit-animation-iteration-count: 1;
          animation-fill-mode: forwards;
          -ms-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -webkit-animation-fill-mode: forwards;

          span {
            overflow: hidden;
          }
        }

        &:hover .thumb_info {
          animation: galleryImgHover .8s ease;
          -ms-animation: galleryImgHover .8s ease;
          -moz-animation: galleryImgHover .8s ease;
          -webkit-animation: galleryImgHover .8s ease;
          animation-iteration-count: 1;
          -ms-animation-iteration-count: 1;
          -moz-animation-iteration-count: 1;
          -webkit-animation-iteration-count: 1;
          animation-fill-mode: forwards;
          -ms-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -webkit-animation-fill-mode: forwards;
        }
      }
    }
  }
}