@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* CONTENT responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";


// max xs width
@include media-breakpoint-down(xs) {
  #doc-content > .container,
  .navbar-header > .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

// max sm width
@include media-breakpoint-down(sm) {
  .row_content_and_sidebar {

    > .col-md-8 {

      #content {
        padding-right: 0 !important;
      }

      .doc-list {
        padding-right: 0 !important;

        .doc_block {

          .doc_block_inner {

            .row {

              .doc_block_txt {

                .row {

                  .col-md-3 {
                    order: unset;
                  }
                  .col-md-9 {
                    order: unset;
                    padding-left: 0;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
        .month_heading {
          right: 0px !important;
        }
      }
      .maintext {

        .maintext_inner {

          .maintext_components {

            .sisea-results-list {
              padding-right: 0 !important;

              .month_heading {
                right: 0px !important;
              }
            }
          }
        }
      }
    }

    /* sidebar */
    .sidebar {

      .sidebar-box {

        .doc_block {

          .doc_image {
          }

          .doc_block_inner {
            padding: 10px 0;

            .fb-page {
              width: 100%;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

// max md width
@include media-breakpoint-down(md) {
}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
  // Styles
}
