@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* GALLERY responsive ****************************************************/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";



// max xs width
@include media-breakpoint-up(xs) {
  .thumbs {

    .thumbs_list {

      .gal-item {
        min-width: calc(33.333333% - 6.666666px);
        max-width: calc(33.333333% - 6.666666px);
        &.gal-item-heads {
          min-width: calc(50% - 13.333333px);
          max-width: calc(50% - 13.333333px);
          margin: 0 20px 20px 0;
          &:nth-of-type(2n) {
            margin: 0 0px 20px 0;
          }
        }

        &:nth-of-type(4n) {
          margin: 0 10px 10px 0;
        }

        &:nth-of-type(3n):not(.gal-item-heads) {
          margin: 0 0 10px 0;
        }

        &.link_back_outer {

          .ajaxlink {
            line-height: 174px;

            &:hover {
            }

            .link_back {
            }
          }
        }

        .thumb_link, .ajaxlink {

          &:visited,
          &:focus {
          }

          .thumb {
          }

          .thumb_info {

            span {
            }
          }

          &:hover .thumb_info {
          }
        }
      }

      &.single-image {
        .gal-item {
          min-width: calc(100%);
          max-width: calc(100%);
        }
      }
    }
  }
}

// max sm width
@include media-breakpoint-up(sm) {
  .thumbs {

    .thumbs_list {

      .gal-item {
        min-width: calc(33.333333% - 6.666666px);
        max-width: calc(33.333333% - 6.666666px);

        &:nth-of-type(4n) {
          margin: 0 10px 10px 0;
        }

        &:nth-of-type(3n):not(.gal-item-heads) {
          margin: 0 0 10px 0;
        }

        &.link_back_outer {

          .ajaxlink {
            line-height: 174px;

            &:hover {
            }

            .link_back {
            }
          }
        }

        .thumb_link, .ajaxlink {

          &:visited,
          &:focus {
          }

          .thumb {
          }

          .thumb_info {

            span {
            }
          }

          &:hover .thumb_info {
          }
        }
      }
    }
  }
}

//// max md width
//@include media-breakpoint-up(md) {
//  .thumbs {
//
//    .thumbs_list {
//      padding-right: 0;
//
//      .gal-item {
//        min-width: calc(30% - 6px);
//        max-width: calc(30% - 6px);
//
//        &:nth-of-type(4n) {
//          margin: 0 10px 10px 0;
//        }
//
//        &:nth-of-type(3n):not(.gal-item-heads) {
//          margin: 0 0 10px 0;
//        }
//
//        &.link_back_outer {
//
//          .ajaxlink {
//            min-height: 100%;
//            min-width: 100%;
//            max-height: 100%;
//            max-width: 100%;
//            line-height: 156px;
//
//            &:hover {
//            }
//
//            .link_back {
//            }
//          }
//        }
//      }
//    }
//  }
//}

// min sm and max md width
@include media-breakpoint-between(sm, md) {
}

// min md width
@include media-breakpoint-up(md) {
  #content {

    .thumbs {

      .thumbs_list {
        padding-right: 0 !important;
      }
    }
  }
  .thumbs {

    .thumbs_list {
      padding-right: 30px;

      .gal-item {
        min-width: calc(33.333333% - 6.666666px);
        max-width: calc(33.333333% - 6.666666px);

        &:nth-of-type(4n) {
          margin: 0 10px 10px 0;
        }

        &:nth-of-type(3n):not(.gal-item-heads) {
          margin: 0 0 10px 0;
        }

        &.link_back_outer {

          .ajaxlink {
            line-height: 143px;

            &:hover {
            }

            .link_back {
            }
          }
        }

        .thumb_link, .ajaxlink {
          min-height: 100%;
          min-width: 100%;
          max-height: 100%;
          max-width: 100%;

          &:visited,
          &:focus {
          }

          .thumb {
          }

          .thumb_info {

            span {
            }
          }

          &:hover .thumb_info {
          }
        }
      }
    }
  }
}

// min lg width
@include media-breakpoint-up(lg) {
  .thumbs {

    .thumbs_list {

      .gal-item {

        &.link_back_outer {

          .thumb_link, .ajaxlink {
            line-height: 196px;
          }
        }
      }
    }
  }
}

// min lg width
@include media-breakpoint-up(xl) {
  .thumbs {

    .thumbs_list {

      .gal-item {

        &.link_back_outer {

          .thumb_link, .ajaxlink {
            line-height: 249.3px;
          }
        }
      }
    }
  }
}