@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_variables.scss";@import "./assets/components/webpackComponents/themeBootstrapOverides/scss/_mixins.scss";/* custom classes */
.hidden {
  display: none;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
//.padding-left-35 {
//  padding-left: 35px;
//}
//.padding-left-45 {
//  padding-left: 45px;
//}
//.padding-right-35 {
//  padding-right: 35px !important;
//}
//.margin-bottom-30 {
//  margin-bottom: 30px;
//}
//.padding-bottom-30 {
//  padding-bottom: 30px;
//}

.padding-top-bottom-20 {
  padding: 20px 0;
}
.padding-bottom-20 {
  padding: 0 0 20px 0;
}